@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Raleway:wght@400;500;600&display=swap');


*{
  margin: 0;
  padding: 0;

  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  background: #F3F3F3;
}

@media only screen and (max-width: 600px) {
  .mobile-icon {
    display: block;
     /* Show the icon on mobile devices */
    }
    .mobile-icon > svg {
    color: #fff;
    font-size: 24px;
  }
  .mobile-text {
    display: none;
  }
}

@media only screen and (min-width: 601px) {
  .mobile-icon {
    display: none; /* Hide the icon on larger screens */
  }
}